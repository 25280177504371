import React, { Component, useEffect, useState, createContext } from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import Index from './index';
import Menu from '../menu/menu';
import NotFound from './notFound';
import Footer from '../footer/footer';

export const AbonentContext = createContext(null);

function Content() {
    
//    const urlServer = "http://localhost:8740";
    const urlServer="";

    return(
            <AbonentContext.Provider value={{}}>
                <Routes>
                    <Route path="/" element={ < Index urlServer = {urlServer} / > }/>
                    <Route path="/index.html" element={ < Index urlServer = {urlServer} / > }/>
                    <Route path="*" element={ < NotFound / > }/>
                </Routes>
            </AbonentContext.Provider>
    )
}

export default Content