import React, { Component, useState, useEffect, useContext, useRef } from 'react';
import {Dialog, DialogContent, Button, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, styled, Paper, AppBar, Tabs, Tab, Box, Typography, Tooltip, TextField, RadioGroup, FormControlLabel, Radio, FormGroup, Checkbox } from "@mui/material";
import { Link } from 'react-router-dom';
import Moment from 'moment';
import InputMask from "react-input-mask";
import ReactPlayer from 'react-player';
import { AbonentContext } from './content';
import Header from '../header/header';
import Prizes from '../prizes/prizes';
import Rules from '../rules/rules';
import Check from '../check/check';
import Message from '../modal/message';
import End from '../modal/end';
import Logo from '../../images/logo.png';

function Index(props) {
    
    const [questionNumber, setQuestionNumber] = useState(1);
    const [question, setQuestion] = useState([]);
    const [questionCount, setQuestionCount] = useState(0);
    const [abonentAnswer, setAbonentAnswer] = useState([]);
    const [abonent, setAbonent] = useState([]);
    const [showModalMessage, setShowModalMessage] = useState(false);
    const [modalMessageText, setModalMessageText] = useState("");
    const [modalMessageType, setModalMessageType] = useState(1);
    const [value, setValue] = useState('');
    const answerId = useRef('');

    useEffect(() => {
        fetch(props.urlServer + "/json/get/question?id=" + questionNumber, {
            method: "GET",
            credentials: "include"
        }).then((response) => {
            return response.json();
        }).then((resp) => {
            setQuestion(resp)
            $('.MuiRadio-root').removeClass('Mui-checked');
            $('.MuiRadio-root span .MuiSvgIcon-root path').attr('d','M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z');
            $('.MuiCheckbox-root').removeClass('Mui-checked');
            $('.MuiCheckbox-root .MuiSvgIcon-root path').attr('d','M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z');
        }, (error) => {

        })
    }, [questionNumber])

    useEffect(() => {
        fetch(props.urlServer + "/json/get/questionCount", {
            method: "GET",
            credentials: "include"
        }).then((response) => {
            return response.json();
        }).then((resp) => {
            setQuestionCount(resp)
            $('.MuiRadio-root').removeClass('Mui-checked');
            $('.MuiRadio-root span .MuiSvgIcon-root path').attr('d','M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z');
            $('.MuiCheckbox-root').removeClass('Mui-checked');
            $('.MuiCheckbox-root .MuiSvgIcon-root path').attr('d','M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z');
        }, (error) => {

        })
    }, [questionNumber])

    function handleButtonClear() {
        setValue('');
        setAbonentAnswer([]);
        setQuestionNumber(1);
    }

    function handleChangeAnswer(event) {
        const value = event.target.value;
        const id = event.target.id;
        const name = event.target.name;
        const checked = event.target.checked;
        let text = null;
        const blockText = $('#block_answer_'+id+'_comment');
        setValue(value);
        
        if (blockText.length > 0) {
            if (checked == true) {
                blockText.css('display','block');
                answerId.current = id;
            } else {
                blockText.css('display','none');
                $('#answer_'+id+'_comment').val('');
                answerId.current = '';
            }
        }
        if (id == 'text') {
            if (name) {
                    setAbonent({...abonent, [name]: value});
            } else {
                const answer = abonentAnswer.find((answer) => answer.question == questionNumber);
                if (answer) {
                    answer.question_answer_text = text;
                    answer.question_text = value;
                } else {
                    setAbonentAnswer([...abonentAnswer, {question: questionNumber, question_answer_id: null, question_answer_checked: true, question_answer_text: text, question_text: value}]);
                }
            }
        } else {
            if (name == 'checkbox' || name == 'comment') {
                if (answerId.current == '') {
                    const answer = abonentAnswer.find((answer) => answer.question_answer_id == id);
                    if (answer) {
                        answer.question_answer_id = id;
                        answer.question_answer_checked = checked;
                        answer.question_answer_text = text;
                    } else {
                        setAbonentAnswer([...abonentAnswer, {question: questionNumber, question_answer_id: id, question_answer_checked: checked, question_answer_text: text, question_text: null}]);
                    }
                } else {
                    const answer = abonentAnswer.find((answer) => answer.question_answer_id == answerId.current);
                    text = value;
                    if (answer) {
                        answer.question_answer_id = answerId.current;
                        answer.question_answer_checked = true;
                        answer.question_answer_text = text;
                    } else {
                        setAbonentAnswer([...abonentAnswer, {question: questionNumber, question_answer_id: answerId.current, question_answer_checked: true, question_answer_text: text, question_text: null}]);
                    }
                }
            } else if (name == 'radio'){
                const answer = abonentAnswer.find((answer) => answer.question == questionNumber);
                if (answer) {
                    answer.question_answer_id = id;
                    answer.question_answer_checked = checked;
                    answer.question_answer_text = text;
                } else {
                    setAbonentAnswer([...abonentAnswer, {question: questionNumber, question_answer_id: id, question_answer_checked: checked, question_answer_text: text, question_text: null}]);
                }
            } else {
                setAbonent({...abonent, [name]: value});
            }
        }
    }

    function handlButtonNext() {
        const regEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (questionNumber < questionCount) {
            if (value !== '' && question.type != 'checkbox') {
                if (regEmail.test(abonent.email) == false) {
                    setModalMessageType(-1);
                    setShowModalMessage(true);
                    setModalMessageText('Неверный формат E-mail');
                } else {
                    answerId.current = '';
                    setValue('');
                    setQuestionNumber(questionNumber + 1);
                    $('.MuiRadio-root').removeClass('Mui-checked');
                    $('.MuiRadio-root span .MuiSvgIcon-root path').attr('d','M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z');
//                    $('.MuiCheckbox-root').removeClass('Mui-checked');
//                    $('.MuiCheckbox-root .MuiSvgIcon-root path').attr('d','M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z');
                    $('html, body').animate({scrollTop: parseInt($("#question").offset().top) - 45}, 100);
                }
            } else if (value !== '' && question.type == 'checkbox') {
                const answers = abonentAnswer.filter((answers) => answers.question == questionNumber && answers.question_answer_checked == true);
                if (answers.length > 0) {
                    if (regEmail.test(abonent.email) == false) {
                        setModalMessageType(-1);
                        setShowModalMessage(true);
                        setModalMessageText('Неверный формат E-mail');
                    } else {
                        answerId.current = '';
                        setValue('');
                        setQuestionNumber(questionNumber + 1);
//                        $('.MuiRadio-root').removeClass('Mui-checked');
//                        $('.MuiRadio-root span .MuiSvgIcon-root path').attr('d','M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z');
                        $('.MuiCheckbox-root').removeClass('Mui-checked');
                        $('.MuiCheckbox-root .MuiSvgIcon-root path').attr('d','M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z');
                        $('html, body').animate({scrollTop: parseInt($("#question").offset().top) - 45}, 100);
                    }
                } else {
                    setModalMessageType(-1);
                    setShowModalMessage(true);
                    setModalMessageText('Не выбран вариант ответа');
                }
            } else {
                setModalMessageType(-1);
                setShowModalMessage(true);
                setModalMessageText('Не выбран вариант ответа');
            }
        } else if (questionNumber == questionCount) {
            if (value !== '' && question.type != 'checkbox') {
                answerId.current = '';
                setValue('');
                fetch(props.urlServer + "/json/form/survey", {
                    method: "POST",
                    credentials: "include",
                    body: JSON.stringify({abonentAnswer: abonentAnswer, abonent: abonent}),
                    headers: {
                        "X-XSRF-TOKEN": document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1"),
                        "Content-Type": "application/json"
                    }
                }).then((response) => {
                    return response.json();
                }).then((resp) => {
                    if (resp.result === 1) {
                        setModalMessageType(1)
                        setShowModalMessage(true)
                        setModalMessageText(resp.msg)
                        setAbonentAnswer([]);
                        setQuestionNumber(1);
                    } else {
                        setModalMessageType(-1)
                        setShowModalMessage(true)
                        setModalMessageText(resp.msg)
                    }
                }, (error) => {

                })
                $('.MuiRadio-root').removeClass('Mui-checked');
                $('.MuiRadio-root span .MuiSvgIcon-root path').attr('d','M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z');
//                $('.MuiCheckbox-root').removeClass('Mui-checked');
//                $('.MuiCheckbox-root .MuiSvgIcon-root path').attr('d','M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z');
                $('html, body').animate({scrollTop: parseInt($("#question").offset().top) - 45}, 100);
            } else if (value !== '' && question.type == 'checkbox') {
                const answers = abonentAnswer.filter((answers) => answers.question == questionNumber && answers.question_answer_checked == true);
                if (answers.length > 0) {
                    answerId.current = '';
                    setValue('');
                    fetch(props.urlServer + "/json/form/survey", {
                        method: "POST",
                        credentials: "include",
                        body: JSON.stringify({abonentAnswer: abonentAnswer, abonent: abonent}),
                        headers: {
                            "X-XSRF-TOKEN": document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1"),
                            "Content-Type": "application/json"
                        }
                    }).then((response) => {
                        return response.json();
                    }).then((resp) => {
                        if (resp.result === 1) {
                            setModalMessageType(1)
                            setShowModalMessage(true)
                            setModalMessageText(resp.msg)
                            setAbonentAnswer([]);
                            setQuestionNumber(1);
                        } else {
                            setModalMessageType(-1)
                            setShowModalMessage(true)
                            setModalMessageText(resp.msg)
                        }
                    }, (error) => {

                    })
//                    $('.MuiRadio-root').removeClass('Mui-checked');
//                    $('.MuiRadio-root span .MuiSvgIcon-root path').attr('d','M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z');
                    $('.MuiCheckbox-root').removeClass('Mui-checked');
                    $('.MuiCheckbox-root .MuiSvgIcon-root path').attr('d','M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z');
                    $('html, body').animate({scrollTop: parseInt($("#question").offset().top) - 45}, 100);
                } else {
                    setModalMessageType(-1);
                    setShowModalMessage(true);
                    setModalMessageText('Не выбран вариант ответа');
                }
            } else {
                setModalMessageType(-1)
                setShowModalMessage(true)
                setModalMessageText('Не выбран вариант ответа')
            }
        }
    }

    function handleCloseModalMessage() {
        if (modalMessageType === 1) {
            setModalMessageText("")
            setShowModalMessage(false)
        } else {
            setModalMessageText("")
            setShowModalMessage(false)
        }
    }

    let ansver;

    if (question.length != 0) {
        if (question.type == 'radio') {
//            $('.MuiRadio-root').removeClass('Mui-checked');
//            $('.MuiRadio-root span .MuiSvgIcon-root path').attr('d','M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z');
            ansver = (<>             
                            {
                                question.answer.length > 7 ?
                                (
                                    <RadioGroup className="flex-direction-row" value={value} onChange={handleChangeAnswer}>
                                        {question.answer.map((data, index) => {
                                            if (data.type == 'comment') {
                                                return (
                                                    <div className="col-md-6 answer_comment">
                                                        <FormControlLabel value={data.question_answer} control={ <Radio id={data.question_answer_id} icon={<span><svg viewBox="0 0 24 24"><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg></span>} checkedIcon={<span><svg viewBox="0 0 24 24"><path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg></span>} name={question.formName != null ? question.formName : 'radio'}/> } label={<Typography dangerouslySetInnerHTML={{__html: data.question_answer}} />} key={index}/>
                                                        <div className="block-input" id={'block_answer_'+data.question_answer_id+'_comment'} style={{display: 'none'}}>
                                                            <TextField variant="standard" id={'answer_'+data.question_answer_id+'_comment'} className={'answer_'+data.question_answer_id+'_comment'} name="comment" onChange={handleChangeAnswer}/>
                                                        </div>
                                                    </div>
                                                    )
                                            } else {
                                                return (
                                                    <div className="col-md-6">
                                                        <FormControlLabel value={data.question_answer} control={ <Radio id={data.question_answer_id} icon={<span><svg viewBox="0 0 24 24"><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg></span>} checkedIcon={<span><svg viewBox="0 0 24 24"><path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg></span>} name={question.formName != null ? question.formName : 'radio'}/> } label={<Typography dangerouslySetInnerHTML={{__html: data.question_answer}} />} key={index}/>
                                                    </div>
                                                    )
                                            }
                                        })}
                                    </RadioGroup>
                                )
                                :
                                (
                                    <RadioGroup value={value} onChange={handleChangeAnswer}>
                                        {question.answer.map((data, index) => {
                                            if (data.type == 'comment') {
                                                return (
                                                        <div className="answer_comment">
                                                            <FormControlLabel value={data.question_answer} control={ <Radio id={data.question_answer_id} icon={<span><svg viewBox="0 0 24 24"><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg></span>} checkedIcon={<span><svg viewBox="0 0 24 24"><path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg></span>} name={question.formName != null ? question.formName : 'radio'}/> } label={<Typography dangerouslySetInnerHTML={{__html: data.question_answer}} />} key={index}/>
                                                            <div className="block-input" id={'block_answer_'+data.question_answer_id+'_comment'} style={{display: 'none'}}>
                                                                <TextField variant="standard" id={'answer_'+data.question_answer_id+'_comment'} className={'answer_'+data.question_answer_id+'_comment'} name="comment" onChange={handleChangeAnswer}/>
                                                            </div>
                                                        </div>
                                                       )
                                                                                                    } else {
                                                                                                        return (
                                                                                                                <FormControlLabel value={data.question_answer} control={ < Radio id = {data.question_answer_id} icon = {<span><svg viewBox="0 0 24 24"><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg></span>} checkedIcon = {<span><svg viewBox="0 0 24 24"><path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg></span>} name={question.formName != null ? question.formName : 'radio'}/> } label={<Typography dangerouslySetInnerHTML={{__html: data.question_answer}} />} key={index}/>
                                                       )
                                            }
                                        })}
                                    </RadioGroup>
                                )
                            }
                        
                      </>)
        }
        if (question.type == 'checkbox') {
//            $('.MuiCheckbox-root').removeClass('Mui-checked');
//            $('.MuiCheckbox-root .MuiSvgIcon-root path').attr('d','M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z');
            ansver = (<>             
                            {
                                question.answer.length > 7 ?
                                (
                                    <FormGroup className="flex-direction-row" value={value} onChange={handleChangeAnswer}>
                                        {question.answer.map((data, index) => {
                                            if (data.type == 'comment') {
                                                return (
                                                        <div className="col-md-6 answer_comment">
                                                            <FormControlLabel value={data.question_answer} control={ <Checkbox id={data.question_answer_id} name={question.formName != null ? question.formName : 'checkbox'}/> } label={<Typography dangerouslySetInnerHTML={{__html: data.question_answer}} />} key={index}/>
                                                            <div className="block-input" id={'block_answer_'+data.question_answer_id+'_comment'} style={{display: 'none'}}>
                                                                <TextField variant="standard" id={'answer_'+data.question_answer_id+'_comment'} className={'answer_'+data.question_answer_id+'_comment'} name="comment" onChange={handleChangeAnswer}/>
                                                            </div>
                                                        </div>
                                                       )
                                            } else {
                                                return (
                                                        <div className="col-md-6">
                                                            <FormControlLabel value={data.question_answer} control={ <Checkbox id={data.question_answer_id} name={question.formName != null ? question.formName : 'checkbox'}/> } label={<Typography dangerouslySetInnerHTML={{__html: data.question_answer}} />} key={index}/>
                                                        </div>
                                                       )
                                            }
                                        })}
                                    </FormGroup>
                                )
                                :
                                (
                                    <FormGroup value={value} onChange={handleChangeAnswer}>
                                        {question.answer.map((data, index) => {
                                            if (data.type == 'comment') {
                                                return (
                                                        <div className="answer_comment">
                                                            <FormControlLabel value={data.question_answer} control={ <Checkbox id={data.question_answer_id} name={question.formName != null ? question.formName : 'checkbox'}/> } label={<Typography dangerouslySetInnerHTML={{__html: data.question_answer}} />} key={index}/>
                                                            <div className="block-input" id={'block_answer_'+data.question_answer_id+'_comment'} style={{display: 'none'}}>
                                                                <TextField variant="standard" id={'answer_'+data.question_answer_id+'_comment'} className={'answer_'+data.question_answer_id+'_comment'} name="comment" onChange={handleChangeAnswer}/>
                                                            </div>
                                                        </div>
                                                       )
                                            } else {
                                                return (
                                                        <FormControlLabel value={data.question_answer} control={ <Checkbox id={data.question_answer_id} name={question.formName != null ? question.formName : 'checkbox'}/> } label={<Typography dangerouslySetInnerHTML={{__html: data.question_answer}} />} key={index}/>
                                                       )
                                            }
                                        })}
                                    </FormGroup>
                                )
                            }
                        
                      </>)
        }
        if (question.type == 'input') {
            ansver = (
                        <div className="block-input" id="block-text">
                            <TextField variant="standard" value={value} id="text" name={question.formName} onChange={handleChangeAnswer}/>
                        </div>
                     )
        }
    } else {
        ansver = (<></>);
    }

    return (
            <>
            {1<0 &&
                <>
                <div className="block-content">
                    <div className="top">
                        <div className="top-logo">
                            <img src={Logo} />
                        </div>
                        <div className="top-text">
                            <h1>
                                Мы проводим маркетинговое исследование, чтобы сделать наши промо-акции еще лучше!
                            </h1>
                            <p>
                                Пройдите опрос и получите 50 рублей на телефон.
                            </p>
                        </div>
                    </div>
                    <div className="survey">
                        <h1 id="question" dangerouslySetInnerHTML={{__html: question.name ? questionNumber+'. '+question.name : questionNumber+'. '}}></h1>
                        {ansver}
                        <Button className="button-bottom" onClick={handlButtonNext}>Далее</Button>
                    </div>
                </div>
                <Message showModalMessage={showModalMessage} modalMessageText={modalMessageText} handleCloseModalMessage={handleCloseModalMessage}/>
                </>
            }
            {1>0 &&
                    <h1>Опрос завершен.</h1>
            }
            </>
            )
}

export default Index